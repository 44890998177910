export default {
  login: login,
  changePwd: changePwd,
  getMachineNum: getMachineNum,
  getGroupList: getGroupList,
  getGroupMachineList: getGroupMachineList,
  updateGroupMachine: updateGroupMachine,
  updateGroupMachineName: updateGroupMachineName,
  getMachineByGroup: getMachineByGroup,
  getMachineDetail: getMachineDetail,
  getMacineSensor: getMacineSensor,
  getMachinePumpWorkStatus: getMachinePumpWorkStatus,
  getMachinePumpHistory: getMachinePumpHistory,
  getMachineSensorDataWithHour: getMachineSensorDataWithHour,
  getMachineSensorHistory: getMachineSensorHistory,
  getWebCamList: getWebCamList,
  sendWebcamCmd: sendWebcamCmd,
  getModuleControl: getModuleControl,
  getModulePumpStatus: getModulePumpStatus,
  getModuleLightValue: getModuleLightValue,
  getModuleLightAuxValue: getModuleLightAuxValue,
  getModuleFanValue: getModuleFanValue,
  updateMachine: updateMachine,
  updateModuleLightValue: updateModuleLightValue,
  updateModuleLightAuxValue: updateModuleLightAuxValue,
  updateModuleFanValue: updateModuleFanValue,
  updateModuleControl: updateModuleControl,
  getMachineEquipDetail: getMachineEquipDetail,
  getMachineModuleConfigList: getMachineModuleConfigList,
  updateMachineModulePumpSwitch: updateMachineModulePumpSwitch,
  setEZUIKitPtz: setEZUIKitPtz,
  getMemberMachineInfo: getMemberMachineInfo,
  clearWebUserGroupMember: clearWebUserGroupMember,
  getMapJsonData: getMapJsonData,
}

import axios from 'axios'
//axios.defaults.baseURL = '/api';
let prefix = "/api";
let mapPrefix = "/map";

//登陆
function login(data, success, error) {
  var resUrl = prefix + '/webuser/login' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//更改密码
function changePwd(data, success, error) {
  var resUrl = prefix + '/webuser/changepwd' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取设备数量
function getMachineNum(data, success, error) {
  axios({
    method: 'get',
    url: prefix + '/machine/getMachineNum',
    data: data,
    headers: {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取分组列表
function getGroupList(data, success, error) {
  var resUrl = prefix + '/machinegroup/getGroupList' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取分组设备列表  单纯分组设备
function getGroupMachineList(data, success, error) {
  var resUrl = prefix + '/machinegroup/getGroupMachine' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

/**
 * 更新分组设备数据
 * @param {*} data 
 * @param {*} success 
 * @param {*} error 
 */
function updateGroupMachine(data, success, error) {
  var resUrl = prefix + '/machinegroup/editordernumlist';
  axios({
    method: 'post',
    url: resUrl,
    data: data,
    headers: {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

/**
 * 更新分组设备名称
 * @param {*} data 
 * @param {*} success 
 * @param {*} error 
 */
function updateGroupMachineName(data, success, error) {
  var resUrl = prefix + '/machinegroup/editdevicenamelist';
  axios({
    method: 'post',
    url: resUrl,
    data: data,
    headers: {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取分组设备
function getMachineByGroup(data, success, error) {
  var resUrl = prefix + '/machinegroup/getMacineByGroup' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}



//获取设备详情-家用机
function getMachineDetail(data, success, error) {
  var resUrl = prefix + '/machine/' + data;
  axios({
    method: 'get',
    url: resUrl,
    data: data,
    headers: {
      'Authorization': '',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}


//获取设备传感器数据
function getMacineSensor(data, success, error) {
  var resUrl = prefix + '/machinesensor/getOne' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取水泵运行记录-家用机
function getMachinePumpWorkStatus(data, success, error) {
  var resUrl = prefix + '/healthmonitor/control-workstatus' + data;
  // console.log(resUrl);
  axios({
    method: 'get',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取水泵运行记录
function getMachinePumpHistory(data, success, error) {
  var resUrl = prefix + '/machinehistory/getControlWorkStatus' + data;
  // console.log(resUrl);
  axios({
    method: 'get',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}
//获取传感器运行记录--家用机
function getMachineSensorDataWithHour(data, success, error) {
  // var resUrl = '/machine/viewsensordata_hour' + data;
  var resUrl = prefix + '/machine/viewsensordata_hour24' + data;
  // console.log(resUrl);
  axios({
    method: 'get',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}
//获取传感器运行记录
function getMachineSensorHistory(data, success, error) {
  // var resUrl = '/machinehistory/getHourList' + data;
  var resUrl = prefix + '/machinehistory/getHour24List' + data;
  // console.log(resUrl);
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取摄像头列表
function getWebCamList(data, success, error) {
  var resUrl = prefix + '/webcam/getList' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//国标摄像头命令发送
function sendWebcamCmd(data, success, error) {
  var resUrl = prefix + '/webcam/sendWebcamCmd' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取设备开关状态 - 二代机
function getModuleControl(data, success, error) {
  var resUrl = prefix + '/machinecontrol/getOne' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取设备水泵状态 - 二代机  工程模组
function getModulePumpStatus(data, success, error) {
  var resUrl = prefix + '/pump/getDetail' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取二代机生长灯亮度
function getModuleLightValue(data, success, error) {
  var resUrl = prefix + '/lightvalue/getList' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取二代机生长灯亮度--辅助光强
function getModuleLightAuxValue(data, success, error) {
  var resUrl = prefix + '/lightauxvalue/getList' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取二代机风扇转速
function getModuleFanValue(data, success, error) {
  var resUrl = prefix + '/fanvalue/getList' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}
//更新生长灯光强
function updateMachine(data, success, error) {
  var resUrl = prefix + '/machine/updatecfgval' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}


//更新生长灯光强
function updateModuleLightValue(data, success, error) {
  var resUrl = prefix + '/lightvalue/updateValue' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//更新生长灯光强-辅助光强
function updateModuleLightAuxValue(data, success, error) {
  var resUrl = prefix + '/lightauxvalue/updateValue' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//更新风扇光强
function updateModuleFanValue(data, success, error) {
  var resUrl = prefix + '/fanvalue/updateValue' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//更新水泵
function updateModuleControl(data, success, error) {
  var resUrl = prefix + '/machinecontrol/updateValue' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取设备外设
function getMachineEquipDetail(data, success, error) {
  var resUrl = prefix + '/machineequipdetail/getEquipDetail' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取工程模组配置列表
function getMachineModuleConfigList(data, success, error) {
  var resUrl = prefix + '/moduleconfig/getList' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//更新工程模组水泵状态
function updateMachineModulePumpSwitch(data, success, error) {
  var resUrl = prefix + '/pump/updatecfgval' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//萤石摄像头云台控制
function setEZUIKitPtz(type, data, success, error){
  var reqUrl = "";
  if(type == "start") {
    reqUrl = "https://open.ys7.com/api/lapp/device/ptz/start";
  } else if(type == "stop") {
    reqUrl = "https://open.ys7.com/api/lapp/device/ptz/stop";
  } else {
    return;
  }
  reqUrl += data;
  axios({
    method: 'post',
    url: reqUrl,
    data: {},
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}



//获取分组设备用户设备信息
function getMemberMachineInfo(data, success, error) {
  var resUrl = prefix + '/webusergroup/getmemberdeviceinfo' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//清除用户分组app用户
function clearWebUserGroupMember(data, success, error) {
  var resUrl = prefix + '/webusergroup/clearmember' + data;
  axios({
    method: 'post',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

//获取map json数据
function getMapJsonData(path, success, error) {
  var resUrl = mapPrefix + path;
  axios({
    method: 'get',
    url: resUrl,
    data: {},
    headers: {
      'Authorization': '',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
    }
  })
  .then(function(res) {
    if(success != null) {
        success(res.data);
    }
  }, function(xhr) {
    if(error != null) {
        error(xhr);
    }
  });
}

