<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">监控</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <div id="webcam-list" class="d-flex jc-center my-video">
        <!-- <CenterLeft2Chart /> -->
        <!-- <div id="video-container0" style="border-radius:10px;display:block;"></div>
        <div id="video-container1" style="border-radius:10px;display:none;"></div>
        <div id="video-container2" style="border-radius:10px;display:none;"></div>
        <div id="video-container3" style="border-radius:10px;display:none;"></div>
        <div id="video-container4" style="border-radius:10px;display:none;"></div>
        <div id="video-container5" style="border-radius:10px;display:none;"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
import  EZUIKit from 'ezuikit-js';
import Transfer from "../utils/transfer";
var myWebcam = [], myWebcamEleArr = [], myWebcamShowId = null;


export default {
  data() {
    return {
      width:260,
      height:330,
      refreshTiming: null,
      replayerTiming: null
    }
  },
  components: {
    // CenterLeft2Chart
  },
  created() {
    window.cameraControlEvent = this.cameraControlEvent;
  },
	mounted(){
    //调用函数
    var globalData = this.$store.state.globalData;
    if(globalData.webcamShow != 1) {
      //未配置摄像头，返回
      //防止报错，还需加载组件，报错：domObserver.disconnect is not a function at VueComponent.unbindDomResizeCallback
      return;
    }
    this.getWebcamList(),
    this.changeTiming();
    Transfer.$on('centerRight1', (msg) => {
      // console.log(msg);
      this.refreshWebcamShow();
    });
    //重新播放
    Transfer.$on('centerRight1-replay', (msg) => {
      var srcIndex = msg.srcIndex;
      myWebcam[srcIndex].player.play();
    });
	},
  beforeDestroy () {
    clearInterval(this.refreshTiming);
    clearInterval(this.replayerTiming);
    this.refreshTiming = null;
    this.replayerTiming = null;

    for(var i = 0; i < myWebcam.length; i++) {
      if(myWebcam[i].player == null) continue;
      myWebcam[i].player.stop();
    }
    myWebcam = [];
    myWebcamEleArr = [];
    myWebcamShowId = null;

    // console.log(myWebcam);
  },
  methods: {
    changeTiming() {
      this.refreshTiming = setInterval(() => {
         this.getWebcamList();
      }, this.$GLOBAL.webcamRefreshTiming)

      this.replayerTiming = setInterval(() => {
        for(var i = 0; i < myWebcam.length; i++) {
          if(myWebcam[i].replayer == false) return;
          myWebcam[i].player.play();
        }
      }, this.$GLOBAL.webcamReplayerTiming)

    },
    
    refreshWebcamShow() {
      var index = myWebcamShowId;
      
      var globalData = this.$store.state.globalData;
      if(myWebcam.length == 0 || index == null || index > myWebcam.length)  return;
      if(globalData.curWebcamSerial == myWebcam[index].webcamSerial) return;

      var curShowEleId = myWebcam[index].eleId;
      var toShowEleId = "";
      for(var i = 0; i < myWebcam.length; i++) {
        if(globalData.curWebcamSerial == myWebcam[i].webcamSerial) {
          toShowEleId = myWebcam[i].eleId;
          myWebcamShowId = i;
        }
      }
      if(toShowEleId == '') return;
      
      document.getElementById(curShowEleId).setAttribute('style', "display:none;");
      document.getElementById(toShowEleId).setAttribute('style', "display:inline-block;");
    },
    getWebcamList() {
      var that = this;
      var globalData = this.$store.state.globalData;
      if(globalData.curGroupId == null && globalData.curGroupName == '') return;
      
      var resData = "?groupId=" + globalData.curGroupId + "&groupName=" + globalData.curGroupName;
      if(globalData.curGroupId == null) {
        resData = "?groupId=&groupName=" + globalData.curGroupName;
      }
      this.$httpApi.getWebCamList(resData, function(d) {
        console.log(JSON.stringify(d)); 
        if(d == null || d.message == null || d.message.length == 0) return;

        var data = d.message;
        if(globalData.accessToken == data[0].accessToken && myWebcamShowId != null) return;

        //开始打开摄像头
        globalData.accessToken = data[0].accessToken;
        
        //清空元素
        var webcamListEle = document.getElementById('webcam-list');
        webcamListEle.innerHTML = '';
        
        for(var i = 0; i < data.length; i++) {
          //停止播放
          if(myWebcam[i] != null && myWebcam[i].player != null) {
            myWebcam[i].player.stop();
          }
          var eleId = "container" + i;
          var videoEleId = "video-container" + i;

          //记录数据， 用于刷新
          myWebcam[i] = {};
          myWebcam[i].uniqueWebcamSerial = data[i].uniqueDeviceSerial;
          myWebcam[i].webcamSerial = data[i].deviceSerial;
          myWebcam[i].monitorUrl = data[i].monitorUrl;
          myWebcam[i].audioPlay = (data[i].audioPlay == 1 ? 1 : 0);
          myWebcam[i].ptz = data[i].ptz;
          myWebcam[i].eleId = eleId;
          myWebcam[i].reload = false; //true: 重新获取
          myWebcam[i].replayer = false; //true: 重新播放

          //用于索引
          myWebcamEleArr[i] = videoEleId;

          //添加元素
          var node = document.createElement("div");
          node.setAttribute('id', eleId);

          //遮罩层
          var maskNode = document.createElement("div");
          maskNode.setAttribute('id', videoEleId + "-mask");
          maskNode.setAttribute('style', "position:absolute;width:" + that.width + "px;height:" + that.height + "px;z-index:998;background:#000000;opacity:0;");
          maskNode.setAttribute('onclick', "cameraControlEvent(" + i + ")");
          node.appendChild(maskNode);

          var videNode = document.createElement("div");
          videNode.setAttribute('id', videoEleId);
          videNode.setAttribute('style', "border-radius:10px;");
          
          node.appendChild(videNode);

          

          if(globalData.curWebcamSerial == '' || data[i].deviceSerial == globalData.curWebcamSerial) {
            node.setAttribute('style', "display:inline-block;");
            webcamListEle.appendChild(node);

            //记录当前播放监控序列号
            globalData.curUniqueWebcamSerial = data[i].uniqueDeviceSerial;
            globalData.curWebcamSerial = data[i].deviceSerial;
            myWebcamShowId = i;
          } else {
            node.setAttribute('style', "display:none;");
            webcamListEle.appendChild(node);
          }
          
          //开始播放监控
          myWebcam[i].player = new EZUIKit.EZUIKitPlayer({
            id: videoEleId, // 视频容器ID
            accessToken: globalData.accessToken,
            url: data[i].monitorUrl,
            // width:280,
            width:that.width, //260,
            height:that.height, //330,
            audio: data[i].audioPlay,
            autoplay:true,
            handleSuccess: function(msg) {
              // console.log(JSON.stringify(msg));
              var tmpIndex = myWebcamEleArr.indexOf(msg.id);
              myWebcam[tmpIndex].replayer = false;
            },
            handleError: function(msg) {
              console.log("open error=" + JSON.stringify(msg));
              //{"retcode":9048,"msg":"免费版并发数达到上限，请升级企业版使用多并发能力","id":"video-container0","type":"handleError"}
              //console.log("----------------msg=" + JSON.stringify(msg));
              //https://open.ys7.com/help/377
              //https://club.ys7.com/portal.php?mod=view&aid=46
              //10002: accessToken过期或异常
              //9048: 开放平台路数超限
              //5404: 设备不在线
              //6519: 设备推流链路网络不稳定
              //6520: 设备推流链路数据格式异常、网络异常
              //1003: 取流网络断开
              //5405: 流媒体向设备发送或接受信令超时/cas响应超时
              if(msg == null) return;
              if(msg.retCode == 10002) {
                var tmpIndex = myWebcamEleArr.indexOf(msg.id);
                if(tmpIndex != '' && tmpIndex != null && tmpIndex < myWebcam.length) {
                  myWebcam[tmpIndex].reload = true;
                }
              } 
              //else if(msg.retcode == 5404 || msg.retcode == 6519 || msg.retcode == 6520 || msg.retcode == 1003 || msg.retcode == 5405 || msg.retcode == 9048) {
              else if(msg.retcode == 5404 || msg.retcode == 6519 || msg.retcode == 6520 || msg.retcode == 1003 || msg.retcode == 5405 || msg.retcode == 9048 || msg.retcode == 9049) {
                var tmpIndex = myWebcamEleArr.indexOf(msg.id);
                if(tmpIndex != '' && tmpIndex != null && tmpIndex < myWebcam.length) {
                  myWebcam[tmpIndex].replayer = true;
                }
              }
            }
            
          });
          
        }
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });

    },
    //摄像头控制打开
    cameraControlEvent(ind) {
      if(myWebcam[ind].ptz != 1) return;

      var sendData = {};
      sendData.srcIndex = ind;
      sendData.index = myWebcam.length + ind;
      sendData.switch = true;
      sendData.uniqueDeviceSerial = myWebcam[ind].uniqueWebcamSerial;
      sendData.deviceSerial = myWebcam[ind].webcamSerial;
      sendData.monitorUrl = myWebcam[ind].monitorUrl;
      sendData.audioPlay = myWebcam[ind].audioPlay;

      myWebcam[ind].player.stop();
      Transfer.$emit('navDataScreen-index-cameraControl', sendData);
      
    }
  }
	

};
</script>

<style lang="scss" scoped>
#centerRight1 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .my-video {
    margin-top: 10px;
  }
  
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>