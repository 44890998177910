<template>
    <div id="appBind">
        <div style="display:flex;flex-direction:row;">
            <div style="width:400px;display:flex;flex-direction:column;align-items:center;margin-top:10px;">
                <span>绑定用户：{{memberName}}</span>
                <div id="qrcode" ref="qrcode" style="margin-top:10px;margin-bottom:10px;width:190px;height:190px;background-color:#ffffff;padding:5px;"></div>
                <span>APP扫码绑定</span>
                <button class="clear-bind-btn" @click="clearAppBind()">解除绑定</button>
            </div>
            <div style="background-color:#252f5b;width:2px;height:950px;margin-left:50px;margin-right:50px;"></div>
            <div>
                <div class="content-box">
                    <div v-for="(item, index) in curData" :key="index" style="width:345px;">
                        <div class="item">
                            <span style="margin-right:5px;">{{item.machineId}}</span>
                            <input disabled type="text" :value="item.deviceName"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="fun-tip" :style="{display:tipdisplay}">
            <span style="color:#d3d6dd;">{{tipText}}</span>
        </div>
    </div>
</template>

<script>

import QRCode from 'qrcodejs2';


export default {
    data() {
        return {
            memberName: '',
            curData: [
                //{deviceName: "1", machineId:"999999999991",groupId:1},
                //{deviceName: "2", machineId:"999999999992",groupId:1},
                //{deviceName: "3", machineId:"999999999993",groupId:1},
                //{deviceName: "4", machineId:"999999999994",groupId:1},
                //{deviceName: "5", machineId:"999999999995",groupId:1},
                //{deviceName: "6", machineId:"999999999996",groupId:1},
                //{deviceName: "7", machineId:"999999999997",groupId:1},
                //{deviceName: "8", machineId:"999999999998",groupId:1},
                //{deviceName: "9", machineId:"999999999999",groupId:1},
                //{deviceName: "10", machineId:"999999999990",groupId:1},
            ],
            tipdisplay: "none",
            tipText: '',
        }
    },
    components: {

    },
    mounted() {
        this.generateQRCode();
        this.getWebUserGroupMemberMachineInfo();
    },
    beforeDestroy () {

    },
    methods: {
        generateQRCode() {
            var curUserId = this.$store.state.curUserId;
            var globalData = this.$store.state.globalData;
            var timestamp = Date.now();
            timestamp = parseInt(timestamp / 1000);
            
            var data = {};
            data.type = 2;
            data.webUserId = curUserId;
            data.groupId = globalData.curGroupId;
            data.timestamp = timestamp;

            var codeText = JSON.stringify(data);
            
            //let qrcode = new QRCode('qrcode', {
            let qrcode = new QRCode(this.$refs.qrcode, {
                width: 180, // 宽度
                height: 180, // 高度
                text: codeText, // 二维码内容(链接，文字，图等)
                render: 'canvas', // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
                colorDark: "#0f1325", //二维码颜色
                colorLight: "#fff", //二维码背景颜色
            })
            qrcode.clear();
			qrcode.makeCode(codeText);
            

        },
        getWebUserGroupMemberMachineInfo() {
            let that = this;
            var globalData = this.$store.state.globalData;
            var curUserId = this.$store.state.curUserId;

            var resData = "?userId=" + curUserId + "&groupId=" + globalData.curGroupId;
            this.$httpApi.getMemberMachineInfo(resData, function(data) {
                //console.log(JSON.stringify(data)); 
                if(data == null || data.status != 200) {
                    return;
                }
                that.memberName = (data.message.memberName == null ? "" : data.message.memberName);
                that.curData = data.message.machineGrouplist;
                
            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        clearAppBind() {
            if(this.memberName == null || this.memberName == '' || this.memberName == undefined) {
                that.tipShowFun(that, "没有绑定,无需解绑");
                return;
            }

            let that = this;
            var globalData = this.$store.state.globalData;
            var curUserId = this.$store.state.curUserId;

            var resData = "?userId=" + curUserId + "&groupId=" + globalData.curGroupId;
            this.$httpApi.clearWebUserGroupMember(resData, function(data) {
                console.log(JSON.stringify(data)); 
                if(data == null || data.status != 200) {
                    that.tipShowFun(that, "解除绑定失败");
                    return;
                }
                that.memberName = "";
                that.tipShowFun(that, "解除绑定成功");
                for(var i = 0; i < that.curData.length; i++) {
                    that.curData[i].deviceName = "";
                }
                
            }, function(xhr) {
                console.log(xhr.responseText);
                that.tipShowFun(that, "解除绑定异常");
            });
        },
        //消息提示
        tipShowFun(that, txt) {
            that.tipText = txt;
            that.tipdisplay = "block";
            setTimeout(() => {
                that.tipdisplay = "none";
                that.tipText = '';
            }, 1000);
        },
    }
}

</script>
<style lang="scss" scoped>

#appBind {
    width:90%;
    height:100%;
    margin:10px auto;
    margin-top:10px;
    color: #d3d7f7;
    font-size: 18px;
    text-align: center;

    .content-box {
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }

    .item {
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin:15px 20px;
    }

    input {
        width:200px;
        height:35px;
        text-align: center;
        border: 1px solid #257dff;
        background-color:rgba(0,0,0,0);
        font-size: 18px;
        color:rgb(0, 186, 255);
    }
    
    span {
        margin-left:10px;
    }

    .clear-bind-btn {
        margin-top:40px;
        width:150px;
        height:50px;
        background-color:#0f1325;
        border:0px;
        color:#fff;
        font-size:18px;
    }

    .fun-tip {
        position:absolute;
        left:45%;
        bottom:80px;
        padding:15px 50px;
        background-color: rgba(19, 25, 47, 0.6);
        border:2px solid rgb(46, 96, 153);
        border-radius:12px;
    }
}

</style>
