<template>
    <div id="deviceName">
        <div class="saveBtn" @click="saveEvent()">保 存</div>
        <div class="content-box">
            <div v-for="(item, index) in curData" :key="index" style="width:345px;">
                <div class="item">
                    <span style="margin-right:5px;">{{item.machineId}}</span>
                    <input type="text" :value="item.deviceName" @change="deviceNameEvent($event, index)"/>
                </div>
            </div>
        </div>
        
        <div class="fun-tip" :style="{display:tipdisplay}">
            <span style="color:#d3d6dd;">{{tipText}}</span>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            curData: [
                //{deviceName: "1", machineId:"999999999991",groupId:1},
                //{deviceName: "2", machineId:"999999999992",groupId:1},
                //{deviceName: "3", machineId:"999999999993",groupId:1},
                //{deviceName: "4", machineId:"999999999994",groupId:1},
                //{deviceName: "5", machineId:"999999999995",groupId:1},
                //{deviceName: "6", machineId:"999999999996",groupId:1},
                //{deviceName: "7", machineId:"999999999997",groupId:1},
                //{deviceName: "8", machineId:"999999999998",groupId:1},
                //{deviceName: "9", machineId:"999999999999",groupId:1},
                //{deviceName: "10", machineId:"999999999990",groupId:1},
            ],
            tipdisplay: "none",
            tipText: '',
        }
    },
    components: {

    },
    mounted() {
        this.getGroupMachine();
    },
    beforeDestroy () {

    },
    methods: {
        //序号编号
        deviceNameEvent(e, ind) {
            this.curData[ind].deviceName = e.target.value;
        },
        getGroupMachine() {
            let that = this;
            var globalData = this.$store.state.globalData;
            var resData = "?groupId=" + globalData.curGroupId + "&groupName=" + globalData.curGroupName;;
            this.$httpApi.getGroupMachineList(resData, function(data) {
                // console.log(JSON.stringify(data)); 
                if(data == null) {
                    return;
                }
                that.curData = data;
                
            }, function(xhr) {
                console.log(xhr.responseText);
            });
        },
        saveEvent() {
            
            let that = this;
            var resData = this.curData;
            
            this.$httpApi.updateGroupMachineName(resData, function(data) {
                //console.log(JSON.stringify(data)); 
                if(data.result == true) {
                    that.tipShowFun(that, "修改成功");
                } else {
                    that.tipShowFun(that, "修改失败");
                }
            }, function(xhr) {
                console.log(xhr.responseText);
                that.tipShowFun(that, "修改异常");
            });

        },
        //消息提示
        tipShowFun(that, txt) {
            that.tipText = txt;
            that.tipdisplay = "block";
            setTimeout(() => {
                that.tipdisplay = "none";
                that.tipText = '';
            }, 1000);
        },
    }
}

</script>
<style lang="scss" scoped>

#deviceName {
    width:70%;
    margin:10px auto;
    margin-top:10px;
    color: #d3d7f7;
    font-size: 18px;
    text-align: center;

    .saveBtn {
        padding:15px 20px;
        border:1px solid #257dff;
    }

    .content-box {
        width:100%;
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
    }

    .item {
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
        margin:15px 20px;
    }

    input {
        width:200px;
        height:35px;
        text-align: center;
        border: 1px solid #257dff;
        background-color:rgba(0,0,0,0);
        font-size: 18px;
        color:rgb(0, 186, 255);
    }
    
    span {
        margin-left:10px;
    }

    .fun-tip {
        position:absolute;
        left:45%;
        bottom:80px;
        padding:15px 50px;
        background-color: rgba(19, 25, 47, 0.6);
        border:2px solid rgb(46, 96, 153);
        border-radius:12px;
    }
}

</style>
