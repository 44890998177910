<template>
    <div id="heBeiMapView">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
                <span>
                    <icon name="chart-pie" class="text-icon"></icon>
                </span>
                <div class="d-flex">
                    <span class="fs-xxl text mx-2" style="white-space:nowrap;overflow:hidden;text-overflow: ellipsis;">{{title}}</span>
                    <dv-decoration-1 class="dv-dec-1" />
                </div>
            </div>
            <div class="d-flex jc-center">
                <div style="width:350px;height:345px" ref="chartsDOM"></div>
            </div>
        </div>
    </div>
    <!--<div style="width:360px;height:410px" ref="chartsDOM"></div>-->
</template>

<script>

import * as echarts from 'echarts';
import axios from "axios";

export default {
  data() {
    return {
        title: '',
        mapJsonPath: '',
        province: '河北',
        city: '石家庄市',
        region: '鹿泉区',
        mapDatas: [],
        mapType: '',
        tipName: '',
        tipCoord: [],
    };
  },
  mounted () {
    this.initView();
    this.initMapJson();
    
  },
  beforeDestroy () {

  },
  methods: {
    initView() {
        let globalData = this.$store.state.globalData;
        var provinceAdcode = globalData.provinceAdcode;
        var cityAdcode = globalData.cityAdcode;

        this.province = globalData.province;
        this.city = globalData.city;
        this.region = globalData.region;
        this.tipCoord[0] = globalData.longitude;
        this.tipCoord[1] = globalData.latitude;
        
        if(globalData.region == null) {
            this.title = this.province + "-" + this.city;
            this.mapType = this.province;
            this.tipName = this.city;
            this.mapJsonPath = "/province/" + provinceAdcode + ".json";
        } else {
            this.title = this.province + "-" + this.city + "-" + this.region;
            this.mapType = this.city;
            this.tipName = this.region;
            this.mapJsonPath = "/province/" + provinceAdcode + "/" + cityAdcode + ".json";
        }

        let regionList = globalData.regionList;
        for(var i = 0; i < regionList.length; i++) {
            var item = regionList[i];
            var mapItem = {};
            mapItem.name = item.regionName;
            mapItem.value = item.adcode;
            this.mapDatas[i] = mapItem;
        }
    },
    initMapJson() {
        
        let that = this;
        this.$httpApi.getMapJsonData(this.mapJsonPath, function(d) {
            that.initEcharts(d);
        }, function(xhr) {
            console.log(xhr.responseText);
        });

    },
    initEcharts(jsonData) {
        let that = this;
        
        // 再得到数据的基础上，进行地图绘制
        //var getMap = fetch(this.mapJsonPath)
        //.then(response => response.json())
        //.then(json => {

        
        //var getMap = mapAxios.get(this.mapJsonPath);
        //var getMap = axios.get(this.mapJsonPath);
        
        //var getMap = axios({
        //    method: 'get',
        //    url: this.mapJsonPath,
        //    data: {},
        //    headers: {
        //    'Authorization': '',
        //    'Access-Control-Allow-Origin': '*',
        //    }
        //});

        
        //getMap.then(res => {   
            var minVal = that.minCode;
            var maxVal = that.maxCode;
            var registerMapName = that.mapType;  //that.province;
            var mapType = registerMapName;
            var effectName = that.tipName; 
            var effectCoord = that.tipCoord;
            var mapDatas = that.mapDatas;
            
            
            // 初始化统计图对象
            let myChart = echarts.init(this.$refs["chartsDOM"]);
            // 显示 loading 动画
            myChart.showLoading();

            // 得到结果后，关闭动画
            myChart.hideLoading();
            // 注册地图(数据放在axios返回对象的data中哦)
            echarts.registerMap(registerMapName, jsonData);

            var option = {
                showLegendSymbol: true,
                //鼠标悬浮，展示相关指数tip
                tooltip: {
                    trigger: 'item',
                    textStyle: {
                        fontSize: 14,
                        lineHeight: 22,
                    },
                    position: point => {
                        // 固定在顶部
                        return [point[0] + 50, point[1] - 20];
                    },
                    // 如果需要自定义 tooltip样式，需要使用formatter
                    
                    //    formatter: params => {
                    //    return `<div style=""> ... </div>`
                    //    }
                    
                },
                visualMap: {
                    min: 0,
                    max: 10,
                    show: false,
                    seriesIndex: 0,
                    // 颜色
                    inRange: {
                        color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
                    },
                },
                // 底部背景
                geo: {
                    show: true,
                    aspectScale: 0.85, //长宽比
                    zoom: 1.22, //1.2,
                    //top: '9%',
                    //left: '10%',
                    map: mapType,
                    roam: false,
                    itemStyle: {
                        normal: {
                            areaColor: 'rgba(0,0,0,0)',
                            shadowColor: 'rgba(7,114,204, .8)',
                            shadowOffsetX: 5,
                            shadowOffsetY: 5,
                        },
                        emphasis: {
                            areaColor: '#00aeef',
                        },
                    },
                },
                series: [
                {
                    name: registerMapName,
                    type: 'map',
                    map: registerMapName,// 这个是上面注册时的名字哦，registerMap（'这个名字保持一致'）
                    aspectScale: 0.85, //长宽比
                    zoom: 1.22, //1.2,
                    mapType: mapType, // 自定义扩展图表类型
                    //top: '9%',
                    //left: '10%',
                    itemStyle: {
                        normal: {
                            color: 'red',
                            //areaColor: 'rgba(19,54,162, .5)',
                            //borderColor: 'rgba(0,242,252,.3)',
                            areaColor: '#4870ea',
                            borderColor: '#00c4cc',
                            borderWidth: 1,
                            shadowBlur: 2,
                            shadowColor: '#00c4cc',
                        },
                        emphasis: {
                            //areaColor: '#4f7fff',
                            //borderColor: 'rgba(0,242,252,.6)',
                            areaColor: '#0045ff',
                            borderColor: '#00f2fc',
                            borderWidth: 2,
                            shadowBlur: 10,
                            shadowColor: '#00f2fc',
                        },
                    },
                    label: {
                        // formatter: params => `${params.name}`,
                        formatter: function (params, ticket, callback) {
                            // console.log(params.name.length);
                            var allLen = params.name.length;
                            var itemLen = 6;

                            var retStr = params.name.substr(0, itemLen);
                            if(allLen > itemLen) {
                                retStr += "\n";
                                retStr += params.name.substr(itemLen);
                            }
                            return retStr;
                            // return params.name;
                        },
                        width: 2,
                        show: true,
                        position: 'insideRight',
                        textStyle: {
                            fontSize: 14,
                            color: '#efefef',
                        },
                        emphasis: {
                            textStyle: {
                                color: '#fff',
                            },
                        },
                    },
                    data: mapDatas,
                },
                {
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    symbolSize: 18,
                    effectType: 'ripple',
                    legendHoverLink: false,
                    showEffectOn: 'render',
                    rippleEffect: {
                        period: 4,
                        scale: 8, //2.5,
                        brushType: 'stroke',
                    },
                    zlevel: 1,
                    itemStyle: {
                        normal: {
                            // color: '#99FBFE',
                            color: '#9900ff',
                            shadowBlur: 5,
                            shadowColor: '#fff',
                        },
                    },
                    data: [{name: effectName, value: effectCoord}],
                    tooltip: {
                        trigger: 'item',
                        textStyle: {
                            fontSize: 14,
                            lineHeight: 22,
                        },
                        position: point => {
                            // 固定在顶部
                            return [point[0] + 50, point[1] - 20];
                        },
                        // 如果需要自定义 tooltip样式，需要使用formatter
                        formatter: function (params, ticket, callback) {
                            return '<div style="display:flex;flex-direction:row;align-items:center;">'
                                + '<span style="background-color:#9900ff;height:10px;width:10px;border-radius:5px;margin-right:5px;"></span>'
                                + '<span>' + params.name + '</span>'
                                + '</div>';
                        }
                    },
                },
                ]
                
            };
            
            myChart.setOption(option);
        //});
    },
  }
  
};

</script>
<style lang="scss" scoped>

#heBeiMapView {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}

</style>

