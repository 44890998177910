<template>
  <div id="centerLeft3">
    <div class="bg-color-black">
      
      <div style="display:flex;flex-direction: column;">
        <div style="padding-right:10px;display:flex;flex-direction: row;align-items:center;justify-content:space-between;">
          <div style="display:flex;flex-direction: row;align-items:center;">
            <span>
              <icon name="chart-line" class="text-icon"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">设备列表 - {{machineNum}}台</span>
            </div>
          </div>
          <!--<span class="text" @click="transformBegin()">回到顶部</span>-->
        </div>
        <div class="list-header" :style="`background-color: ${config['headerBGC']}};z-index:999;opacity:1;`" @click="transformBegin()">
          <span :style="{width:config.columnWidth[0] + 'px'}">#</span>
          <span :style="{width:config.columnWidth[1] + 'px'}">编号</span>
          <span :style="{width:config.columnWidth[2] + 'px'}">在线</span>
          <span :style="{width:config.columnWidth[3] + 'px'}">展示</span>
        </div>
      </div>
      
      <div class="d-flex jc-center body-box">
        <!-- <dv-scroll-board class="dv-scr-board" :config="config" @click="dvScrollBoardClickEvent" /> -->
        <div id="parentList" class="list">
          
          <div id="allListItem">
            
            <div  v-for="(item, index) in config.data" :key="index">
              <div :id="'item' + index" class="list-item" :style="`background-color: ${config[index % 2 === 0 ? 'evenRowBGC' : 'oddRowBGC']};`" @click="dvScrollBoardClickEvent(index)">
                <span :style="{width:config.columnWidth[0] + 'px'}">{{index + 1}}</span>
                <span :style="{width:config.columnWidth[1] + 'px'}" class="list-item-name">{{item[3] == "" ? item[0] : item[3]}}</span>
                <span :style="{width:config.columnWidth[2] + 'px'}">{{item[1]}}</span>
                <span :style="{width:config.columnWidth[3] + 'px'}">{{item[2]}}</span>
              </div>
            </div>

          </div>

        </div>
          
      </div>
      
    </div>
  </div>
</template>

<script>
import Transfer from "../utils/transfer.js";

export default {
  data() {
    return {
      timing: null,
      getMachineTiming: null,
      machineNum: 1,
      machineArr: [],
      endTransform: false,
      curShowIndex: 0,
      config: {
        header: ['编号', '在线', '展示'],
        data: [
          ['872201010001', "是", "√", ""],
          ['872201010002', "是", "", ""],
        ],
        //rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: false,
        columnWidth: [40, 120, 50, 50],
        align: ['center', 'center', 'center', 'center'],
        indexHeader: '#',
        index: true,
        waitTime: 3000,
      }
    }
  },
  mounted() {
    this.changeTiming();
    this.changeRefreshTiming();
    this.getData();

    Transfer.$on('groupMachineList', (msg) => {
       //console.log(msg);
       this.getData();
     });
    // Transfer.$on('centerLeft3', (msg) => {
    //   //console.log(msg);
    //   this.getData();
    // });
  },
  beforeDestroy () {
    if(this.timing != null) {
      clearInterval(this.timing);
      this.timing = null;
    }
    if(this.getMachineTiming != null) {
      clearInterval(this.getMachineTiming);
      this.getMachineTiming = null;
    }
  },
  methods: {
    changeTiming() {
      this.timing = setInterval(() => {
        var globalData = this.$store.state.globalData;
        if(globalData.machineLoop == false) return;

        this.changeDisplay();
        
        this.transformFun();
        
      }, this.$GLOBAL.deviceShowTiming);
    },
    changeRefreshTiming() {
      this.getMachineTiming = setInterval(() => {
        this.getData();
      }, this.$GLOBAL.deviceRefreshTiming);
    },
    //位移事件
    transformFun() {
      var showHeight = document.getElementById("parentList").clientHeight;

      var ele = document.getElementById("allListItem");
      var listHeight = ele.scrollHeight;
      if(listHeight <= showHeight) return;
      
      var toScrollHeight = 0;
      for(var i = 0; i < this.curShowIndex; i++) {
        toScrollHeight += document.getElementById("item" + i).clientHeight;
      }
      
      if(listHeight - toScrollHeight < showHeight) {
        toScrollHeight = listHeight - showHeight;
      }

      var top = toScrollHeight;

      //开始位移
      top = -top;
      ele.style.transform = "translateY(" + top + "px)";
      ele.style.transition = "all 1s";

    },
    /*
    //位移事件
    transformFun1() {
      //向上滚动
      if(this.config.data.length <= this.config.rowNum) {
        return;
      }

      var ele = document.getElementById("allListItem");
      var top = document.getElementsByClassName("list-item")[0].clientHeight;

      if(this.curShowIndex >= (this.config.data.length - this.config.rowNum + 1) && this.curShowIndex <= (this.config.data.length  - 1)) {
        //位移到最后一页，确保展示最后一页，执行一次
        if(this.endTransform == false) {
          top *= this.config.data.length - this.config.rowNum;
          top = -top;
          ele.style.transform = "translateY(" + top + "px)";
          ele.style.transition = "all 1s";

          this.endTransform = true;
        }
        return;
      }
      this.endTransform = false;
      
      //开始位移
      top *= this.curShowIndex;
      top = -top;
      ele.style.transform = "translateY(" + top + "px)";
      ele.style.transition = "all 1s";
    },
    */
    //回到顶部
    transformBegin() {
      this.changeDisplay(this.config.data[0][0], 0);
      this.transformFun();
    },
    //设备列表点击事件
    dvScrollBoardClickEvent(ind) {
      // console.log(JSON.stringify(d));
      //停止定时器
      if(this.timing != null) {
        clearInterval(this.timing);
        this.timing = null;
      }

      //修改展示设备
      var d = this.config.data[ind];
      var rowIndex = ind;
      var toMachine = d[0];
      this.changeDisplay(toMachine, rowIndex);
      
      this.transformFun();
    },
    changeDisplay(toMachine, toRowIndex) {
      var curIndex = 0;
      for(var i = 0; i < this.config.data.length; i++) {
        var item = this.config.data[i];
        if(item[2] == "√") {
          curIndex = i;
          break;
        }
      }
      if(curIndex == toRowIndex) return;

      this.config.data[curIndex][2] = ""; 

      if(toMachine == undefined) {
        if((curIndex + 1) >= this.config.data.length) {
          curIndex = 0;
        } else {
          curIndex++;
        }
      } else {
        curIndex = toRowIndex;
      }
      this.preShowIndex = this.curShowIndex;
      this.curShowIndex = curIndex;

      this.config.data[curIndex][2] = "√";
      
      // this.$store.commit("setCurMachine", this.config.data[curIndex][0]);
      this.$store.commit("setCurMachine", this.machineArr[curIndex].machineId);
      this.$store.commit("setCurMachineName", this.machineArr[curIndex].deviceName);
      this.$store.commit("setCurOnline", this.machineArr[curIndex].online);
      this.$store.commit("setCurStyleSize", this.machineArr[curIndex].styleSize);
      this.$store.commit("setCurProtocolType", this.machineArr[curIndex].protocolType);
      this.$store.commit("setCurLayerNum", this.machineArr[curIndex].layerNum);
      this.$store.commit("setCurLayerFan", this.machineArr[curIndex].layerFan);
      this.$store.commit("setCurWebcamSerial", this.machineArr[curIndex].webcamSerial);

      var tmpNumData = {};
      tmpNumData.pumpNum = this.machineArr[curIndex].pumpNum;
      tmpNumData.curPumpNo = 1;
      tmpNumData.pumpList = this.machineArr[curIndex].pumpList;

      tmpNumData.envSensorNum = this.machineArr[curIndex].envSensorNum;
      tmpNumData.curEnvSensorNo = 1;
      tmpNumData.envSensorList = this.machineArr[curIndex].envSensorList;

      tmpNumData.waterSensorNum = this.machineArr[curIndex].waterSensorNum;
      tmpNumData.curWaterSensorNo = 1;
      tmpNumData.waterSensorList = this.machineArr[curIndex].waterSensorList;

      this.$store.commit("setMachineSensorNum", tmpNumData);
      
      this.config = { ...this.config };
      
      //重置设备轮训定时
      //确定轮询次数
      var maxPollingNum = tmpNumData.pumpNum;
      if(maxPollingNum < tmpNumData.envSensorNum) maxPollingNum = tmpNumData.envSensorNum;
      if(maxPollingNum < tmpNumData.waterSensorNum) maxPollingNum = tmpNumData.waterSensorNum;
      this.$GLOBAL.deviceShowTiming = this.$GLOBAL.deviceChildShowTiming * (maxPollingNum + 1);
      if(this.timing != null) {
        clearInterval(this.timing);
        this.timing = null;
      }
      //重启定时器
      this.changeTiming();


      //触发父组件事件
      Transfer.$emit('navDataScreen','msg-index');

    },
    getData() {
      
      var globalData = this.$store.state.globalData;
      if(globalData.curGroupId == null && (globalData.curGroupName == '' || globalData.curGroupName == null)) return;
      
      let that = this;
      var resData = "?groupId=" + globalData.curGroupId + "&groupName=" + globalData.curGroupName;
      if(globalData.curGroupId == null) {
        resData = "?groupId=&groupName=" + globalData.curGroupName;
      }
      
      this.$httpApi.getMachineByGroup(resData, function(data) {
        // console.log(JSON.stringify(data)); 
        var arr = [];
        var tmpArr = [];
        var showIndex = 0;
        if(data != null) {
          that.machineNum = data.length;
        }
        
        for(var i = 0; i < data.length; i++) {
          var item = data[i];
          var tmpObj = {};
          tmpObj.webcamSerial = item.webcamSerial;
          tmpObj.deviceName = ((item.deviceName == null || item.deviceName == undefined) ? "" : item.deviceName);
          tmpObj.machineId = item.machineId;
          tmpObj.protocolType = item.protocolType;
          tmpObj.layerNum = item.layerNum;
          tmpObj.layerFan = item.layerFan;
          tmpObj.styleSize = item.styleSize;
          tmpObj.pumpNum = item.pumpNum;
          tmpObj.envSensorNum = item.envSensorNum;
          tmpObj.waterSensorNum = item.waterSensorNum;
          tmpObj.pumpList = item.pumpList;
          tmpObj.envSensorList = item.envSensorList;
          tmpObj.waterSensorList = item.waterSensorList;
          tmpObj.online = item.online;

          //排序
          if(tmpObj.pumpList != undefined) {
            tmpObj.pumpList.sort((x, y)=> x - y);
          }
          if(tmpObj.envSensorList != undefined) {
            tmpObj.envSensorList.sort((x, y)=> x - y);
          }
          if(tmpObj.waterSensorList != undefined) {
            tmpObj.waterSensorList.sort((x, y)=> x - y);
          }
          
          tmpArr[i] = tmpObj;
          
          var arrData = [];
          arrData[0] = item.machineId;
          arrData[1] = (item.online == 1 ? "是" : "否");
          arrData[2] = "";
          arrData[3] = ((item.deviceName == null || item.deviceName == undefined) ? "" : item.deviceName);
          
          if(globalData.curMachine == item.machineId) {
            showIndex = i;
          }
          // if(i == 0) {
          //   arrData[2] = "√";
            
          //   that.$store.commit("setCurMachine", item.machineId);
          //   that.$store.commit("setCurStyleSize", item.styleSize);
          //   that.$store.commit("setCurProtocolType", item.protocolType);
          //   that.$store.commit("setCurWebcamSerial", item.webcamSerial);
          // }
          arr[i] = arrData;
        }
        arr[showIndex][2] = "√";
        that.$store.commit("setCurMachine", tmpArr[showIndex].machineId);
        that.$store.commit("setCurMachineName", tmpArr[showIndex].deviceName);
        that.$store.commit("setCurOnline", tmpArr[showIndex].online);
        that.$store.commit("setCurStyleSize", tmpArr[showIndex].styleSize);
        that.$store.commit("setCurProtocolType", tmpArr[showIndex].protocolType);
        that.$store.commit("setCurLayerNum", tmpArr[showIndex].layerNum);
        that.$store.commit("setCurLayerFan", tmpArr[showIndex].layerFan);
        that.$store.commit("setCurWebcamSerial", tmpArr[showIndex].webcamSerial);

        var tmpNumData = {};
        tmpNumData.pumpNum = tmpArr[showIndex].pumpNum;
        tmpNumData.curPumpNo = 1;
        tmpNumData.pumpList = tmpArr[showIndex].pumpList;

        tmpNumData.envSensorNum = tmpArr[showIndex].envSensorNum;
        tmpNumData.curEnvSensorNo = 1;
        tmpNumData.envSensorList = tmpArr[showIndex].envSensorList;

        tmpNumData.waterSensorNum = tmpArr[showIndex].waterSensorNum;
        tmpNumData.curWaterSensorNo = 1;
        tmpNumData.waterSensorList = tmpArr[showIndex].waterSensorList;

        that.$store.commit("setMachineSensorNum", tmpNumData);

        that.machineArr = tmpArr;
        that.config.data = arr;
        
        
        that.config = { ...that.config };

        //触发父组件事件
        // console.log(showIndex);
        // if(showIndex == 0) {
          Transfer.$emit('navDataScreen','msg-index');
        // }
        
      }, function(xhr) {
        console.log(xhr.responseText);
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 405px;
$box-width: 300px;
#centerLeft3 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
    font-size: 15px;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 270px;
      height: 340px;
    }
  }

  .list-header {
    width:100%;
    display:flex;
    flex-direction: row;
    text-align: center;
    padding:10px 0px;
    
  }
  
  .list {
    height:305px;
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  
  .list-item {
    width:100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding:12px 0px;
    
  }
  
  .list-item-name {
    white-space: normal;
    overflow-wrap: break-word
  }

}
</style>
