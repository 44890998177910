<template>
    <div id="setting" ref="setting">
        <div class="d-flex jc-between px-2">
            <div class="d-flex aside-width">
                <div class="react-left ml-4 react-l-s">
                    <span class="react-left"></span>
                    <span class="text" @click="returnBtn">< 返回{{returnName}}</span>
                </div>
                <div class="react-left ml-3">
                    <span class="text">{{subTitle}}</span>
                </div>
            </div>
            <div class="d-flex aside-width">
                <div class="react-right bg-color-black mr-3">
                    <span class="text fw-b"></span>
                </div>
                <div class="react-right mr-4 react-l-s">
                    <span class="react-after"></span>
                    <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
                </div>
            </div>
        </div>
        <div style="margin-bottom:50px;"></div>
        <!--<h1 @click="settingFunEvent('setList')">{{title}}</h1>-->
        <div class="content-box" :style="{display:setListDisplay}">
            <div class="content-box-layer content-box-blank">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('appBind')">
                        <span>APP绑定</span>
                    </div>
                </dv-border-box-9>
            </div>
            <div class="content-box-layer content-box-blank">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('deviceName')">
                        <span>设备别名</span>
                    </div>
                </dv-border-box-9>
            </div>
            <div class="content-box-layer content-box-blank">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('deviceOrder')">
                        <span>设备排序</span>
                    </div>
                </dv-border-box-9>
            </div>
            <div class="content-box-layer content-box-blank">
                <dv-border-box-9>
                    <div class="content-item" @click="settingFunEvent('changePwd')">
                        <span>修改密码</span>
                    </div>
                </dv-border-box-9>
            </div>
            
        </div>
        <div :style="{display:setContentListDisplay}">
            <appBind v-if="appBindShow"/>
            <deviceName v-if="deviceNameShow"/>
            <deviceOrder v-else-if="deviceOrderShow"/>
            <changePwd v-else-if="changePwdShow"/>
        </div>
        
    </div>
</template>
<script>

import Transfer from "../utils/transfer";
import { formatTime } from '../utils/index.js'
import appBind from "./appBind"
import deviceName from "./deviceName"
import deviceOrder from "./deviceOrder"
import changePwd from "./changePwd"

export default {
    data() {
        return {
            timing: null,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            returnName: "首页",
            subTitle: "设置",
            //0：设置列表页, 1:设备排序, 2:修改密码
            settingSubPage: 0,
            //title: "设置",
            setListDisplay: "flex",
            setContentListDisplay: "none",
            deviceNameShow: false,
            deviceOrderShow: false,
            changePwdShow: false,
            appBindShow: false,
        }
    },
    components: {
        appBind,
        deviceName,
        deviceOrder,
        changePwd,
    },
    mounted() {
        this.timeFn();
    },
    beforeDestroy () {
        clearInterval(this.timing);
        this.timing = null;
    },
    methods: {
        timeFn() {
            this.dateDay = formatTime(new Date(), 'HH: mm: ss')
            this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
            this.dateWeek = this.weekday[new Date().getDay()];

            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000);
        },
        settingFunEvent(item) {
            this.setListDisplay = "none";
            this.setContentListDisplay = "none";
            this.deviceNameShow = false;
            this.deviceOrderShow = false;
            this.changePwdShow = false;
            this.appBindShow = false;

            switch(item) {
                //case 'setList':
                //    this.returnName = "首页";
                //    this.subTitle = "设置";
                //    this.settingSubPage = 0;

                //    //this.title = "设置";
                //    this.setListDisplay = "flex";
                //    break;
                
                case 'deviceOrder': 
                    this.returnName = "设置";
                    this.subTitle = "设备排序";
                    this.settingSubPage = 1;

                    //this.title = "设置 - 设备排序";
                    this.setContentListDisplay = "block";
                    this.deviceOrderShow = true;
                    break;
                case 'changePwd': 
                    this.returnName = "设置";
                    this.subTitle = "修改密码";
                    this.settingSubPage = 2;
                    
                    //this.title = "设置 - 修改密码";
                    this.setContentListDisplay = "block";
                    this.changePwdShow = true;
                    break;
                
                case 'deviceName': 
                    this.returnName = "设置";
                    this.subTitle = "设备别名";
                    this.settingSubPage = 3;

                    //this.title = "设置 - 设备别名";
                    this.setContentListDisplay = "block";
                    this.deviceNameShow = true;
                    break;

                case 'appBind':
                this.returnName = "设置";
                    this.subTitle = "APP绑定";
                    this.settingSubPage = 4;

                    //this.title = "设置 - APP绑定";
                    this.setContentListDisplay = "block";
                    this.appBindShow = true;
                    break;

                default: break;
            }
        },
        returnBtn() {
            if(this.settingSubPage == 1 || this.settingSubPage == 2 || this.settingSubPage == 3 || this.settingSubPage == 4) {
                this.settingSubPage = 0;
                this.returnName = "首页";
                this.subTitle = "设置";

                this.setContentListDisplay = "none";
                this.deviceOrderShow = false;
                this.changePwdShow = false;
                this.setListDisplay = "flex";
            } else {
                //设置页返回首页
                Transfer.$emit('index-setting', {msg: "返回首页"});
            }
        },
    }
}

</script>
<style lang="scss" scoped>
@import '../assets/scss/setting.scss';
</style>
